.mobile {
  min-width: 83%;
  min-height: 30rem;
}

.header {
  background-color: #fecc0c !important;
  color: #000 !important;
}

.headline {
  font-family: "IBM Plex Sans Hebrew", sans-serif;
  font-size: 15pt;
  font-weight: 700;
}
