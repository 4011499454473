.mobile_drag::-webkit-scrollbar {
  display: none;
}

.mobile_drag {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav {
  margin-right: -2% !important;
  z-index: 2;
  min-width: 20%;
}

.card {
  padding-left: 4%;
  border-radius: 5px;
  min-width: 100%;
  min-height: 670px;
}

@media (min-width: 1200px) {
  .nav {
    margin-left: 5%;
  }
  .title {
    margin-left: 5%;
  }
}
