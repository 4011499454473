.nav_link {
  color: #fff !important;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1100px) {
  .nav_link {
    font-size: 0.76rem;
  }
}

@media (min-width: 1101px) and (max-width: 1199px) {
  .nav_link {
    font-size: 0.84rem;
  }
}

@media (min-width: 1200px) {
  .nav_link {
    font-size: 0.9rem;
  }
}

@media (min-width: 1575px) and (max-width: 1699px) {
  .nav_link {
    font-size: 1rem;
  }
}

@media (min-width: 1700px) {
  .nav_link {
    font-size: 1.2rem;
  }
}
